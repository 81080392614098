<template lang="pug">
    .a-client-details.container.flex
        .mx-auto.w-full.max-w-960.mt-8.mb-8.p-4.bg-white-pure.l-box-shadow.rounded
            v-form(
                :model="isValid"
                ref="clientDetailsForm"
            )
                .w-full.flex.mb-8
                    GoBack(:goBack="historyLink()" :forceGoBack="true")
                    h1(
                        class="w-full text-black mr-2 mb-0 text-xl lg:text-2xl text-center inline-block align-middle w-8/12"
                        data-cy="clientTitle"
                    ) {{title}}
                .w-auto.pb-8
                    ClientForm(
                        v-model="clientDetailsForm"
                        ref="clientForm"
                        :editMode="isEditable"
                    )
                div( class="grid grids-cols-1 lg:grid-cols-2 gap-x-8 gap-y-8 w-full")
                    div
                        h4.font-display.font-bold.text-left.text-lg
                            | Demographics
                        Demographics( ref="demographicsForm" v-model="clientDetailsForm")
                    div
                        h4.font-display.font-bold.text-left.text-lg
                            | Address
                        Address( ref="addressForm" v-model="address" )
                    div
                        h4.font-display.font-bold.text-left.text-lg
                            | Contact
                        Contact( ref="contactForm" v-model="clientDetailsForm" :editMode="isEditable")
                    div
                        h4.font-display.font-bold.text-left.text-lg
                            | Emergency Contact / Next of Kin
                        EmergencyContact( ref="emergencyContactForm" v-model="clientDetailsForm.emergencyContact" )
                    div( v-if="!isEditable" )
                        h4.font-display.font-bold.text-left.text-lg
                            | Episode
                        Episode( ref="episodeForm" v-model="clientDetailsForm.episode" :users="users" )
                br
                v-alert( v-if="!isValid && !showAlert"
                    :closable="false"
                    type="error"
                    class="text-sm"
                ) {{validationWarning}}
                v-alert( v-if="showAlert"
                    :closable="false"
                    :type="type"
                    class="text-sm"
                ) {{message}}
                .flex.flex-row.justify-center.mt-8
                    router-link(
                        v-if="isEditable || isNewClient"
                        :disabled="!buttonEnabled || clientDeleted"
                        tag="span"
                        class="mr-4 w-40 v-btn v-btn--depressed theme--light v-size--default cursor-pointer"
                        :class="{'disabled pointer-events-none': saving}"
                        :to="cancelRoute"
                    ) cancel
                    v-btn(
                        v-if="$can('delete', 'client') && !isNewClient"
                        depressed
                        :disabled="!buttonEnabled || clientDeleted"
                        :class="{'disabled pointer-events-none': saving}"
                        class="mr-4 v-btn--flat inline w-40"
                        type="danger" color="danger"
                        @click.prevent="deleteClient()"
                    )
                        fa-icon(v-if="clientDeleted" icon="spinner" spin class="mr-2 text-primary-light")
                        | {{ deleteButtonText }}
                    v-btn(
                        depressed
                        :disabled="!buttonEnabled || clientDeleted || saving"
                        class="v-btn--flat inline w-40"
                        :class="{'disabled pointer-events-none': saving}"
                        type="success" color="success"
                        @click.prevent="checkForm('clientDetailsForm')"
                    )
                        fa-icon(v-if="saving" icon="spinner" spin class="mr-2 text-primary-light")
                        | {{nextButtonText}}
</template>

<script>
import ClientForm from '@/components/client-details/ClientForm'
import Address from '@/components/shared/Address'
import GlobalHeader from '@/components/shared/GlobalHeader'
import GlobalFooter from '@/components/shared/GlobalFooter'
import { mapState } from 'vuex'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'
import httpMixin from '@/components/shared/mixins/httpMixin'
import BackendHelpers from '@/components/shared/mixins/backendHelpers'
import GoBack from '@/components/shared/GoBack'
import { AuthMixin } from '@/components/shared/mixins/authHelpers'
import Clients from '@/json/clients.json'
import Contact from '@/components/shared/Contact'
import Episode from '@/components/client-details/partials/Episode'
import Demographics from '@/components/client-details/partials/Demographics'
import EmergencyContact from '@/components/client-details/partials/EmergencyContact'
import GET_CLIENT from '@/graphql/queries/getClient.gql'
import INSERT_CLIENT from '@/graphql/mutations/insertClient.gql'
import INVITE_STUDENT from '@/graphql/mutations/inviteStudent.gql'
import UPDATE_STUDENT from '@/graphql/mutations/updateStudent.gql'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import { FormRules } from '@/components/shared/mixins/formMixins'
import { EpisodeSaveHelpers } from '@/components/shared/mixins/episodeMixins'
import PhoneHelper from '@/components/shared/mixins/phoneHelper'
import { CheckUserHelper } from '@/components/shared/mixins/userMixin'
import cloneDeep from 'clone-deep'
import { ref, reactive, onMounted, toRefs, watch } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { useLazyQuery } from '@vue/apollo-composable'
import { storeToRefs } from 'pinia'
import { useClientStore } from '@/stores/useClientStore'
import { userService } from '@/services/user.service'
import { clientService } from '@/services/client.service'

const { useActions } = createNamespacedHelpers('')

export default {
    components: {
        Episode,
        Demographics,
        EmergencyContact,
        Contact,
        GoBack,
        GlobalHeader,
        Address,
        ClientForm,
        GlobalFooter
    },
    mixins: [
        httpMixin,
        BackendHelpers,
        AuthMixin,
        MessageDialog,
        FormRules,
        EpisodeSaveHelpers,
        CheckUserHelper
    ],
    data() {
        return {
            isValid: true,
            saving: false,
            firstLoad: true,
            message: '',
            type: '',
            clientList: Clients,
            validationWarning:
                'Please check you have completed all required fields. Required fields will be highlighted in red above',
            defaultTitle: 'Add client',
            disableMmm: false,
            defaultNextButtonText: 'Next',
            savingButtonText: 'Saving...',
            buttonEnabled: true,
            showAlert: false,
            defaultPhoneNumber: {
                countryCode: 'AU',
                phone: '',
                archived: false
            },
            defaultAddress: {
                unit: '',
                address_line1: '',
                address_line2: '',
                suburb: '',
                postcode: '',
                state: '',
                lat: null,
                lng: null,
                archived: false
            },
            defaultClient: {
                clientId: null,
                firstName: '',
                lastName: '',
                dob: '',
                gender: '',
                episode: {
                    status: '',
                    type: '',
                    userId: '',
                    locationProgramId: ''
                },
                day: 'ACCURATE',
                month: 'ACCURATE',
                year: 'ACCURATE',
                aliases: [],
                address: {
                    unit: '',
                    addressLine1: '',
                    addressLine2: '',
                    suburb: '',
                    postcode: '',
                    state: '',
                    lat: null,
                    lng: null,
                    archived: false
                },
                email: '',
                phone: {
                    countryCode: 'AU',
                    phone: ''
                },
                facebook: '',
                instagram: '',
                emergencyContact: {
                    firstName: '',
                    lastName: '',
                    phone: {
                        countryCode: 'AU',
                        phone: ''
                    },
                    email: '',
                    relationship: ''
                }
            },
            insertExtraColumns: ['start_date', 'end_date'],
            updateEpisodeColumns: ['status', 'location_program_id', 'user_id', 'type'],
            address: {
                unit: '',
                addressLine1: '',
                addressLine2: '',
                suburb: '',
                postcode: '',
                state: '',
                lat: null,
                lng: null,
                archived: false
            }
        }
    },
    mounted() {
        if (this.id !== null && this.clientList) {
            this.client = { ...this.clientList.find((c) => c.id === Number(this.id)) }
            if (this.isEditable) {
                this.clientDetailsForm = { ...this.client }
            }
        }
    },
    methods: {
        historyLink() {
            return !this.client || this.client.clientId == null
                ? this.isNewUser
                    ? { name: 'dashboard' }
                    : { name: 'client-list' }
                : { name: 'episodes', clientId: this.client.clientId }
        },
        validate(formName) {
            const formsToValidate = [
                this.$refs.clientForm.validate(),
                this.$refs.addressForm.validate(),
                this.$refs.contactForm.validate(),
                !this.isEditable ? this.$refs.episodeForm?.validate() : null,
                this.$refs.demographicsForm.validate(),
                this.$refs.emergencyContactForm.validate(),
                this.$refs[formName].validate()
            ]
            // resolving all the validation promises
            return Promise.all(formsToValidate)
        },
        validateForm(formName) {
            return this.validate(formName)
                .then((res) => {
                    return true
                })
                .catch(() => {
                    return false
                })
        },
        async checkForm(formName) {
            this.$refs.contactForm.setEmailExists(false)
            this.isValid = true
            this.showAlert = false

            // If the client that has the same email exists
            if (!this.isEditable || this.emailChanged) {
                const emailExists = await this.checkUserEmailExists(this.clientDetailsForm.email)
                if (emailExists) {
                    this.$refs.contactForm.setEmailExists(true)
                    return
                }
            }

            // resolving all the validation promises
            try {
                const resolved = await this.validate(formName)

                this.buttonEnabled = false
                if (resolved.indexOf(false) !== -1) return Promise.reject('invalid data')
                this.saving = true
                this.setEpisodeStatus(
                    this.clientDetailsForm.episode,
                    false,
                    this.loggedInUser.userId
                )
                let convertedClient
                let { address, episode, ...client } = this.clientDetailsForm
                let clientId = null
                if (client.dob === '') client.dob = null

                delete address.typename
                client.address = {
                    data: address,
                    on_conflict: {
                        constraint: 'address_pkey',
                        update_columns: [
                            'unit',
                            'address_line1',
                            'address_line2',
                            'postcode',
                            'state',
                            'suburb'
                        ]
                    }
                }
                if (episode) {
                    // regardless, we need to remove the below prop since it doesn't exist in Hasura
                    delete episode.typename
                    delete client.episode
                }
                // need to have a different set of update columns when updating the client and now we just ignore them for now
                if (!this.isEditable) {
                    client.episodes = {
                        data: [episode],
                        on_conflict: {
                            constraint: 'episode_pkey',
                            update_columns: episode.episodeId
                                ? this.updateEpisodeColumns
                                : [...this.updateEpisodeColumns, ...this.insertExtraColumns]
                        }
                    }
                } else {
                    delete client.episodes
                    delete client.user
                }
                const emergencyContact = { ...client.emergencyContact }
                if (emergencyContact) {
                    delete emergencyContact.typename
                    emergencyContact.phone = emergencyContact?.phone?.phone || ''
                    client.emergencyContact = {
                        data: snakecaseKeys(emergencyContact, { deep: true }),
                        on_conflict: {
                            constraint: 'contact_pkey',
                            update_columns: [
                                'first_name',
                                'last_name',
                                'phone',
                                'email',
                                'client_id',
                                'relationship'
                            ]
                        }
                    }
                }
                client.alias =
                    client.aliases && client.aliases.length ? client.aliases.join(',') : ''
                client.phone = client?.phone?.phone || ''
                if (client) {
                    delete client.aliases
                    delete client.typename
                }
                // now we get the client ready to be saved
                if (client.clientId == null) {
                    delete client.clientId
                }
                convertedClient = snakecaseKeys(client || {}, {
                    deep: true,
                    exclude: ['__typename']
                })
                this.$apollo
                    .mutate({
                        mutation: INSERT_CLIENT,
                        variables: {
                            client: convertedClient
                        }
                    })
                    .then(({ data: { client } }) => {
                        // if (this.clientId) return
                        const clientMapped = camelcaseKeys(client, { deep: true })
                        const mappedClient = clientMapped.returning[0]
                        clientId = mappedClient.clientId
                        this.clientId = clientId
                        this.client.clientId = clientId

                        if (!mappedClient.email) {
                            if (!this.isEditable) {
                                return Promise.reject({
                                    type: 'warning',
                                    message:
                                        'Saved client successfully. But this young person has no email/mobile that was provided and could not be invited to the app.'
                                })
                            }
                        }

                        let promise = Promise.resolve(client.user || {})
                        // update user info connected with this client
                        if (!this.invited && mappedClient.email)
                            promise = this.inviteStudent(clientId)
                        else if (this.client?.user?.userId) promise = this.updateStudent()
                        return promise
                    })
                    .then(() => {
                        // console.log('APOLLO mutation indicatorResponse, response:', data)
                        // this.saving = false
                        this.showAlert = true
                        this.message =
                            'Saved client successfully, redirecting back to manage client'
                        this.type = 'success'
                        // resetting the current episode value as well
                        this.$store.commit('SET_CURRENT_EPISODE', null)
                    })
                    .catch((error) => {
                        this.type = error?.type ?? 'error'

                        let message = error?.message
                        if (error?.graphQLErrors) message = error?.graphQLErrors[0]?.message
                        if (!message) message = 'Add/Edit failed'
                        this.message = message
                    })
                    .finally(() => {
                        if (this.type === 'error') {
                            this.saving = false
                            this.buttonEnabled = true
                        }
                        if (this.type !== 'success') {
                            this.showAlert = true
                            this.showMessage({ duration: 5000 })
                        }
                        if (this.type !== 'error') {
                            setTimeout(async () => {
                                await this.$apollo.provider.defaultClient.resetStore()

                                await this.$router.push({
                                    name: 'episodes',
                                    params: { clientId: this.client.clientId }
                                })
                            }, 1000)
                        }
                    })
            } catch (error) {
                // this should always return false since it is checking if the form is valid
                this.isValid = false

                this.buttonEnabled = true
            } finally {
                this.buttonEnabled = true
                this.saving = false
            }
        },
        updateStudent() {
            const variables = { userId: this.client?.user?.userId }
            const { role, email } = this.client?.user
            const userInfo = {
                role,
                firstName: this.clientDetailsForm.firstName,
                lastName: this.clientDetailsForm.lastName,
                name: `${this.clientDetailsForm.firstName}  ${this.clientDetailsForm.lastName}`,
                email: this.clientDetailsForm.email === email ? email : this.clientDetailsForm.email
            }

            variables.auth0User = snakecaseKeys(userInfo, { deep: true })
            variables.user = snakecaseKeys(userInfo, { deep: true })

            return this.$apollo.mutate({
                mutation: UPDATE_STUDENT,
                variables
            })
        },
        inviteStudent(clientId) {
            return this.$apollo.mutate({
                mutation: INVITE_STUDENT,
                variables: {
                    clientId: clientId
                }
            })
        },
        initComponent() {
            this.client = cloneDeep(this.defaultClient)
            this.$refs.clientDetailsForm.reset()
        }
    },
    computed: {
        ...mapState({
            staticChoices: (state) => state.app.staticChoices,
            loggedInUser: (state) => state.app.loggedInUser
        }),
        clientDetailsForm: {
            get() {
                const client = cloneDeep(this.client)
                return client && client.clientId != null ? client : cloneDeep(this.defaultClient)
            },
            set(clientDetailsForm) {
                this.$emit('input', clientDetailsForm)
            }
        },
        isNewClient() {
            return this.client?.clientId === null || this.client?.clientId === undefined
        },
        isEditable() {
            return this.client?.clientId != null
        },
        invited() {
            return !!this.client?.user
        },
        cancelRoute() {
            return this.client.clientId
                ? { name: 'episodes', params: { clientId: this.client.clientId.toString() } }
                : { name: 'client-list' }
        },
        title() {
            return !this.isNewClient ? 'Edit client' : this.defaultTitle
        },
        nextButtonText() {
            return this.saving
                ? this.savingButtonText
                : this.isEditable
                ? 'Save'
                : this.defaultNextButtonText
        },
        nextBtnDisabled() {
            return !this.isValid || this.saving
        },
        emailChanged() {
            return this.client.email != this.clientDetailsForm.email
        }
    },
    watch: {
        '$route.path': function (val, oldVal) {
            if (this.$route.params.clientId == null) {
                this.initComponent()
            } else {
                this.$apollo.queries.client.refetch()
            }
        },
        clientDetailsForm: {
            deep: true,
            async handler() {
                this.address = { ...this.clientDetailsForm.address }
                this.$nextTick(async () => {
                    this.isValid = await this.validateForm('clientDetailsForm')
                })
            }
        },
        address: {
            deep: true,
            handler() {
                this.clientDetailsForm.address = this.address
            }
        },
        client() {
            if (this.client && Object.keys(this.client)) this.firstLoad = false
        }
    },
    setup(_, { root }) {
        const state = reactive({
            users: []
        })
        const route = root.$route
        const $router = root.$router
        const clientId = ref(route.params.clientId)
        // const client = ref({})
        const deleteButtonText = ref('Delete')
        const defaultEpisode = {
            status: '',
            type: '',
            userId: '',
            locationProgramId: ''
        }
        const clientStore = useClientStore()
        const { client, clientDeleted } = storeToRefs(clientStore)
        const { updateClient, resetMessage } = clientStore
        const { openModal } = useActions(['openModal'])

        const getClient = async () => {
            try {
                const foundClient = await clientService.getClient(clientId.value)
            } catch (error) {
                console.error('Error fetching client', error)
            }
        }

        const { onResult, loading, error, refetch, load, onError } = useLazyQuery(GET_CLIENT, {
            clientId: clientId.value
        })

        onResult(({ data, loading: queryLoading }) => {
            if (queryLoading) return
            const mappedClient = camelcaseKeys(data?.client, { deep: true })
            mappedClient.aliases = mappedClient.alias ? mappedClient.alias.split(',') : []
            mappedClient.phone = PhoneHelper.parse(mappedClient.phone)
            mappedClient.episode = mappedClient.episodes
                ? mappedClient.episodes[0]
                : { ...defaultEpisode }

            if (mappedClient.emergencyContact) {
                mappedClient.emergencyContact.phone = PhoneHelper.parse(
                    mappedClient.emergencyContact.phone
                )
            }
            // client.value = { ...mappedClient }
            updateClient(mappedClient)
        })

        const deleteClient = () => {
            deleteButtonText.value = 'Deleting...'
            openModal({
                modalName: 'ModalConfirmDeleteClient',
                payload: {
                    clientId: client.value?.clientId,
                    clientName: client.value?.firstName
                }
            })
            setTimeout(() => {
                deleteButtonText.value = 'Delete'
            }, 1000)
        }

        watch(clientDeleted, (value) => {
            if (value) {
                setTimeout(() => {
                    resetMessage()
                    clientDeleted.value = false
                    $router.push({ name: 'client-list' })
                }, 5000)
            }
        })

        onMounted(async () => {
            if (clientId.value) await load()
            const users = await userService.getUsers()

            state.users = [...users]
        })

        return {
            ...toRefs(state),
            deleteButtonText,
            clientId,
            loading,
            error,
            defaultEpisode,
            client,
            clientDeleted,
            deleteClient
        }
    }
}
</script>
